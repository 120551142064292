<template>
  <div id="DeletePatient" class="form-horizontal">
    <div>
      <v-alert
      class="warning"
      dense
      outlined
      >
      <strong>{{ $t('caution_delete_patient') }}</strong>
      </v-alert>
    </div>
    <Form id="DeletePatient" :name="'DeletePatient'" :isEditing="true" :loadFormData="false" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId"/>
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState } from 'vuex'

export default {
  name: 'DeletePatient',
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form
  },
  computed: {
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    back () {
      this.$router.push({ name: 'UnifiedPatient' })
    },
    formSavedSuccessfully () {
      this.back()
    }
  }
}
</script>

<style scoped>
.form-horizontal {
  padding: 20px 0 10px 0;
  background-color: var(--background-color-primary-light-dark);
}
.warning {
  color: #ff5252 !important;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
